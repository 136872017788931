import { Form, InputGroup } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { domainSchema } from './validations'
import { DOMAIN_STATUSES } from '../constants'

function DomainForm(props) {
  const { formRef, onFormSubmit, values } = props
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    resolver: yupResolver(domainSchema),
    defaultValues: values || { status: 'WHITELISTED' },
  })

  return (
    <Form ref={formRef} noValidate onSubmit={handleSubmit(onFormSubmit)}>
      <Form.Group controlId="firstName">
        <Form.Label>Organization</Form.Label>
        <Form.Control
          {...register('organization')}
          size="lg"
          isInvalid={!!errors.organization}
        />
        <Form.Control.Feedback type="invalid">
          {errors.organization?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="domain">
        <Form.Label>Email Domain</Form.Label>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            {...register('domain')}
            size="lg"
            isInvalid={!!errors.domain}
          />
          <Form.Control.Feedback type="invalid">
            {errors.domain?.message}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
      <Form.Group controlId="status">
        <Form.Label>Status</Form.Label>
        {DOMAIN_STATUSES.map(domainStatus => (
          <Form.Check
            type="radio"
            key={domainStatus.value}
            label={domainStatus.label}
            value={domainStatus.value}
            {...register('status')}
            isInvalid={!!errors.status}
          />
        ))}
        <Form.Control.Feedback type="invalid">
          {errors.status?.message}
        </Form.Control.Feedback>
      </Form.Group>
    </Form>
  )
}

export default DomainForm
