import { useContext } from 'react'
import numeral from 'numeral'
import { FloatingContainer } from './styled'
import { Accordion, Button, Card } from 'react-bootstrap'
import GaugeChart from 'react-gauge-chart'
import { Text, Subtitle } from '../../../Themed'
import { PRIMARY_ALT } from '../../../../constants/colors'
import Publish from './Publish'
import { MapContext } from '../context'
import UpdateSite from './UpdateSite'
import SiteAgreements from './SiteAgreements'
import SiteNotes from './SiteNotes'
import CreateSite from './UpdateSite/CreateSite'
import { gql, useMutation } from '@apollo/client'
import Landowners from './Landowners'
import Score from './Score'

const UPDATE_SITE_ADMIN = gql`
  mutation UPDATE_SITE_ADMIN($id: ID!, $site: PreQualifiedSiteInput!) {
    updateSiteAdmin(id: $id, site: $site) {
      id
    }
  }
`

export function ParcelInfo() {
  const {
    selectedParcel: [clickedParcel],
    handleReset,
  } = useContext(MapContext)
  return (
    <FloatingContainer top={16} right={16}>
      <div className="border-bottom p-3">
        <div className="d-flex justify-content-between align-items-center">
          <Subtitle>Parcel {clickedParcel.number}</Subtitle>
          {clickedParcel.financial && (
            <small className="text-muted">
              $
              {numeral(clickedParcel.financial.lcoeReal)
                .multiply(1000)
                .format('0.00')}
              /MWh
            </small>
          )}
        </div>
        <small className="d-block text-muted">
          {new Date(clickedParcel.updatedAt).toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
          })}{' '}
          {new Date(clickedParcel.updatedAt).toLocaleTimeString('en-US')} |{' '}
          {numeral(clickedParcel.acreage).format('0,0.0')} acres
        </small>
      </div>
      <GaugeChart
        id="gauge-chart1"
        percent={clickedParcel.wetlandIntersectionRatio || 0}
        textColor="grey"
      />
      <div className="pl-4"></div>
      <Button className="float-right m-2" onClick={handleReset}>
        <div className="d-flex justify-content-center align-items-center">
          <span className="material-icons mr-2">list</span>
          <span>List</span>
        </div>
      </Button>
    </FloatingContainer>
  )
}

function ListItem(props) {
  const { parcel, onParcelItemClick } = props

  return (
    <div className="border-bottom">
      <div className="d-flex justify-content-between align-items-center">
        <Button
          variant="link"
          className="p-0"
          onClick={() => onParcelItemClick(parcel)}
        >
          Parcel {parcel.number}
        </Button>
      </div>
      <small className="d-block text-muted">
        {new Date(parcel.updatedAt).toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
        })}{' '}
        {new Date(parcel.updatedAt).toLocaleTimeString('en-US')} |{' '}
        {numeral(parcel.acreage).format('0,0.0')} acres
      </small>
    </div>
  )
}

function ParcelList() {
  const {
    site: { sites },
    handleParcelItemClick,
    refetch,
  } = useContext(MapContext)

  const [updateSiteAdmin, { loading, data }] = useMutation(UPDATE_SITE_ADMIN, {
    onCompleted: refetch,
  })

  return (
    <FloatingContainer top={16} right={16}>
      <div className="border-bottom p-3">
        <div className="d-flex align-items-center justify-content-between">
          <Subtitle className="mr-1">Sites</Subtitle>
          <div>
            <CreateSite />
            <Landowners />
          </div>
        </div>
        <Text>{sites.length} sites</Text>
      </div>
      <div className="p-3" style={{ maxHeight: 400, overflowY: 'auto' }}>
        <Accordion>
          {sites.map((site, i) => (
            <Card key={site.id}>
              <Card.Header className="p-0">
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey={String(i)}
                  className="text-decoration-none text-left pl-2 pr-2 pt-0 pb-0"
                >
                  <Text color={PRIMARY_ALT}>{site.name}</Text>
                </Accordion.Toggle>
                <div className="ml-3 mt-0 mb-1 mr-3">
                  <span className="text-muted d-block" style={{ fontSize: 12 }}>
                    {site.address.streetAddress}
                  </span>
                  <span className="text-muted mr-2" style={{ fontSize: 12 }}>
                    {site.parcels.length || 0} parcel(s)
                  </span>
                  <div className="d-flex">
                    <Publish site={site} refetch={refetch} loading={loading} />
                    <UpdateSite
                      className="ml-2"
                      site={site}
                      onUpdate={updateSiteAdmin}
                      loading={loading}
                      isSuccess={!!data?.updateSiteAdmin?.id}
                    />
                    <SiteAgreements
                      className="ml-2 mr-2"
                      site={site}
                      onUpdate={updateSiteAdmin}
                    />
                    <SiteNotes id={site.id} />
                    <Score site={site} />
                  </div>
                </div>
              </Card.Header>
              <Accordion.Collapse eventKey={String(i)}>
                <Card.Body>
                  {site.parcels.map(parcel => (
                    <ListItem
                      key={parcel.id}
                      parcel={parcel}
                      onParcelItemClick={p => handleParcelItemClick(p)}
                    />
                  ))}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          ))}
        </Accordion>
      </div>
    </FloatingContainer>
  )
}

export default ParcelList
