import React, { useContext, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import {
  OTHER_DELIVERY_POINT_OPTIONS,
  POWER_TRADING_HUBS_WTTH_CLEARING_CODES,
  SITING_TYPE_OPTIONS,
  AGREEMENT_TYPE_OPTIONS,
  SITE_TAGS_OPTIONS,
} from './constants'
import { siteDetailsSchema } from './validations'
import { SitesContext } from '../../../..'

function SiteEdit(props) {
  const { companies, landowners } = useContext(SitesContext)

  const [hasKml, setHasKml] = useState(false)
  const [hasThumbnail, setHasThumbnail] = useState(false)
  const { values = {}, formRef, onFormSubmit, siteTechOpts } = props

  let blacklistCompaniesOptions = companies.map(({ id, name }) => ({
    label: name,
    value: id,
  }))

  //Only for companies that have submitted initial SCA.
  let whitelistCompaniesOptions = blacklistCompaniesOptions

  const landownerOptions = landowners.map(({ id, name }) => ({
    label: name,
    value: id,
  }))

  useEffect(() => {
    if (values?.kml?.url) setHasKml(true)
    if (values?.thumbnail) setHasThumbnail(true)
  }, [values])

  const {
    register,
    handleSubmit,
    control,
    watch,

    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...values,
      companyBlacklist: blacklistCompaniesOptions
        .filter(c => values?.companyBlacklist?.includes(c.value))
        .map(({ value }) => value),
      companyWhitelist: whitelistCompaniesOptions
        .filter(c => values?.companyWhitelist?.includes(c.value))
        .map(({ value }) => value),
      landowners: {
        all: landownerOptions
          .filter(l =>
            values?.landowners?.all?.map(({ id }) => id).includes(l.value)
          )
          .map(({ value }) => value),
        anchor: landownerOptions
          .filter(l =>
            values?.landowners?.anchor?.map(({ id }) => id).includes(l.value)
          )
          .map(({ value }) => value),
      },
      firstLookExpiry:
        values.firstLookExpiry ||
        moment().add(7, 'days').utc().toDate().toUTCString(),
    },
    resolver: yupResolver(siteDetailsSchema(values, hasKml, hasThumbnail)),
  })

  const watchTags = watch('tags')
  const watchKmlFile = watch('kmlFile')
  const watchThumbnailFile = watch('thumbnailFile')
  const watchCompanyWhitelist = watch('companyWhitelist')
  const watchCompanyBlacklist = watch('companyBlacklist')
  const watchAllLandowners = watch('landowners.all')

  const anchorLandownerOptions = landownerOptions.filter(landowner =>
    watchAllLandowners?.includes(landowner.value)
  )

  const whiteListOptions = blacklistCompaniesOptions.filter(
    company => !watchCompanyBlacklist?.includes(company.value)
  )
  const blackListOptions = blacklistCompaniesOptions.filter(
    company => !watchCompanyWhitelist?.includes(company.value)
  )

  const styles = {
    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? { ...base, color: 'white', paddingRight: 6 }
        : base
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: 'none' } : base
    },
  }

  return (
    <Form ref={formRef} noValidate onSubmit={handleSubmit(onFormSubmit)}>
      <Row>
        <Form.Group as={Col} controlId="name">
          <Form.Label className="text-muted">Site Name</Form.Label>
          <Form.Control {...register('name')} isInvalid={!!errors.name} />
          <Form.Control.Feedback type="invalid">
            {errors.name?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="technologies">
          <Form.Label className="text-muted">Technologies</Form.Label>
          <Controller
            render={({ field: { onChange, value } }) => (
              <Select
                isMulti
                value={siteTechOpts.filter(techOpt =>
                  (value || []).includes(techOpt.value)
                )}
                options={siteTechOpts}
                onChange={(technologies, actionMeta) => {
                  switch (actionMeta.action) {
                    case 'remove-value':
                    case 'pop-value':
                      if (actionMeta.removedValue.isFixed) {
                        return
                      }
                      break
                    case 'clear':
                      technologies = siteTechOpts.filter(v => v.isFixed)
                      break
                    default:
                      console.log('default case')
                  }

                  const updatedTech = technologies
                    .filter(v => v.isFixed)
                    .concat(technologies.filter(v => !v.isFixed))

                  onChange(updatedTech.map(tech => tech.value))
                }}
                styles={{
                  menu: base => ({ ...base, zIndex: 999 }),
                  ...styles,
                }}
              />
            )}
            name="technologies"
            control={control}
          />
          <Form.Control.Feedback type="invalid" className="d-block">
            {errors.technologies?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      {!values?.project && (
        <React.Fragment>
          <Row>
            <Form.Group as={Col} controlId="kmlFile">
              <Form.Label className="text-muted">KML</Form.Label>
              {hasKml ? (
                <div>
                  <a
                    href={values?.kml?.url}
                    role="button"
                    className="mr-1 btn btn-outline-primary btn-sm"
                    download
                  >
                    Download KML
                  </a>
                  <Button
                    size="sm"
                    variant="outline-danger"
                    onClick={() => setHasKml(false)}
                  >
                    Reset
                  </Button>
                </div>
              ) : (
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <div className="d-flex">
                      <Form.File id="kml-file-upload-input" custom>
                        <Form.File.Input
                          onChange={e => onChange(e.target.files[0])}
                          name="kmlFile"
                          accept=".kml"
                        />
                        <Form.File.Label data-browse="Browse">
                          {watchKmlFile?.name || 'Select KML'}
                        </Form.File.Label>
                        <Form.Control.Feedback type="invalid">
                          {errors.kmlFile?.message}
                        </Form.Control.Feedback>
                      </Form.File>
                      {values?.kml?.url && (
                        <Button
                          onClick={() => setHasKml(true)}
                          variant="outline-danger"
                          className="ml-2"
                        >
                          Cancel
                        </Button>
                      )}
                    </div>
                  )}
                  name="kmlFile"
                  control={control}
                />
              )}
            </Form.Group>
            <Form.Group as={Col} controlId="thumbnailFile">
              <Form.Label className="text-muted">Thumbnail</Form.Label>
              {hasThumbnail ? (
                <div>
                  <a
                    href={values?.thumbnail}
                    role="button"
                    className="mr-1 btn btn-outline-primary btn-sm"
                    download
                  >
                    Download Thumbnail
                  </a>
                  <Button
                    size="sm"
                    variant="outline-danger"
                    onClick={() => setHasThumbnail(false)}
                  >
                    Reset
                  </Button>
                </div>
              ) : (
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <div className="d-flex">
                      <Form.File id="thumbnail-file-upload-input" custom>
                        <Form.File.Input
                          onChange={e => onChange(e.target.files[0])}
                          name="thumbnailFile"
                        />
                        <Form.File.Label data-browse="Browse">
                          {watchThumbnailFile?.name || 'Select Thumbnail'}
                        </Form.File.Label>
                        <Form.Control.Feedback type="invalid">
                          {errors.thumbnailFile?.message}
                        </Form.Control.Feedback>
                      </Form.File>
                      {values?.thumbnail && (
                        <Button
                          onClick={() => setHasThumbnail(true)}
                          variant="outline-danger"
                          className="ml-2"
                        >
                          Cancel
                        </Button>
                      )}
                    </div>
                  )}
                  name="thumbnailFile"
                  control={control}
                />
              )}
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="center.lat">
              <Form.Label className="text-muted">Latitude</Form.Label>
              <Form.Control
                {...register('center.lat')}
                type="number"
                isInvalid={!!errors.center?.lat}
              />
              <Form.Control.Feedback type="invalid">
                {errors.center?.lat?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="center.lng">
              <Form.Label className="text-muted">Longitude</Form.Label>
              <Form.Control
                {...register('center.lng')}
                type="number"
                isInvalid={!!errors.center?.lng}
              />
              <Form.Control.Feedback type="invalid">
                {errors.center?.lng?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="boundaryAreaInAcres">
              <Form.Label className="text-muted">
                Boundary Area (Acres)
              </Form.Label>
              <Form.Control
                {...register('boundaryAreaInAcres')}
                type="number"
                isInvalid={!!errors?.boundaryAreaInAcres}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.boundaryAreaInAcres?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="buildableAreaInAcres">
              <Form.Label className="text-muted">
                Buildable Area (Acres)
              </Form.Label>
              <Form.Control
                {...register('buildableAreaInAcres')}
                type="number"
                isInvalid={!!errors?.buildableAreaInAcres}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.buildableAreaInAcres?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
        </React.Fragment>
      )}
      <Row>
        <Form.Group as={Col} md="6" controlId="landowners.all">
          <Form.Label className="text-muted">Landowners</Form.Label>
          <Controller
            render={({ field: { onChange, value } }) => (
              <Select
                isMulti
                value={landownerOptions.filter(l => value.includes(l.value))}
                options={landownerOptions}
                onChange={options => onChange(options.map(opt => opt.value))}
              />
            )}
            name="landowners.all"
            control={control}
          />
          <Form.Control.Feedback type="invalid" className="d-block">
            {errors.landowners?.all?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="landowners.anchor">
          <Form.Label className="text-muted">Anchor Landowner</Form.Label>
          <Controller
            render={({ field: { onChange, value } }) => (
              <Select
                isMulti
                isOptionDisabled={() => value.length >= 1}
                value={landownerOptions.filter(l => value.includes(l.value))}
                options={anchorLandownerOptions}
                onChange={options => onChange(options.map(opt => opt.value))}
              />
            )}
            name="landowners.anchor"
            control={control}
          />
          <Form.Control.Feedback type="invalid" className="d-block">
            {errors.landowners?.anchor?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} md="6" controlId="offtakerDemandInMWh">
          <Form.Label className="text-muted">Buyer Demand (MWh)</Form.Label>
          <Form.Control
            // size="lg"
            placeholder="(MWh)"
            type="number"
            {...register('offtakerDemandInMWh')}
            isInvalid={!!errors.offtakerDemandInMWh}
          />
          <Form.Control.Feedback type="invalid">
            {errors.offtakerDemandInMWh?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="nOfftakers">
          <Form.Label className="text-muted">Potential Buyers (#)</Form.Label>
          <Form.Control
            // size="lg"
            type="number"
            {...register('nOfftakers')}
            isInvalid={!!errors.nOfftakers}
          />
          <Form.Control.Feedback type="invalid">
            {errors.nOfftakers?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} md="6" controlId="leaseRate">
          <Form.Label className="text-muted">Lease Rate ($/Acre)</Form.Label>
          <Form.Control
            type="number"
            {...register('leaseRate')}
            isInvalid={!!errors.leaseRate}
          />
          <Form.Control.Feedback type="invalid">
            {errors.leaseRate?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="leaseEsc">
          <Form.Label className="text-muted">Lease Escalation (%)</Form.Label>
          <Form.Control
            type="number"
            {...register('leaseEsc')}
            isInvalid={!!errors.leaseEsc}
          />
          <Form.Control.Feedback type="invalid">
            {errors.leaseEsc?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} md="6" controlId="purchasePrice">
          <Form.Label className="text-muted">
            Purchase Price ($/acre)
          </Form.Label>
          <Form.Control
            // size="lg"
            type="number"
            placeholder="($/acre)"
            {...register('purchasePrice')}
          />
          <Form.Control.Feedback type="invalid">
            {errors.nOfftakers?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="firstLookExpiry">
          <Form.Label className="text-muted d-block">
            First Look Expiry
          </Form.Label>
          <Controller
            render={({ field: { onChange, value } }) => (
              <div className="customDatePickerWidth">
                <DatePicker
                  // minDate={new Date().setDate(new Date().getDate() + 1)}
                  dateFormat="MMM/dd/yyyy"
                  selected={new Date(value)}
                  onChange={onChange}
                  customInput={<Form.Control className="w-100" />}
                />
              </div>
            )}
            name="firstLookExpiry"
            control={control}
          />
          <Form.Control.Feedback type="invalid" className="d-block">
            {errors.firstLookExpiry?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} md="6" controlId="iso">
          <Form.Label className="text-muted">ISO</Form.Label>
          <Controller
            render={({ field: { onChange, value } }) => (
              <Select
                options={[
                  ...POWER_TRADING_HUBS_WTTH_CLEARING_CODES,
                  ...OTHER_DELIVERY_POINT_OPTIONS,
                ]}
                defaultValue={[
                  ...POWER_TRADING_HUBS_WTTH_CLEARING_CODES,
                  ...OTHER_DELIVERY_POINT_OPTIONS,
                ].find(option => Object.is(option.value, value))}
                onChange={option => onChange(option.value)}
              />
            )}
            name="iso"
            control={control}
          />
          <Form.Control.Feedback type="invalid" className="d-block">
            {errors.iso?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="transmissionLine">
          <Form.Label className="text-muted">Transmission Line</Form.Label>
          <Form.Control
            placeholder="e.g. NV Energy Alturus"
            {...register('transmissionLine')}
          />
          <Form.Control.Feedback type="invalid">
            {errors.transmissionLine?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} md="6" controlId="bessCapacity">
          <Form.Label className="text-muted">BESS Capacity (MW)</Form.Label>
          <Form.Control
            type="number"
            placeholder="(MW)"
            {...register('bessCapacity')}
          />
          <Form.Control.Feedback type="invalid">
            {errors.bessCapacity?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="bessEnergy">
          <Form.Label className="text-muted">BESS Energy (MWh)</Form.Label>
          <Form.Control
            type="number"
            placeholder="(MWh)"
            {...register('bessEnergy')}
          />
          <Form.Control.Feedback type="invalid">
            {errors.bessEnergy?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} md="6" controlId="type">
          <Form.Label className="text-muted">Site Type</Form.Label>
          <Controller
            render={({ field: { onChange, value } }) => (
              <Select
                options={SITING_TYPE_OPTIONS}
                defaultValue={[...SITING_TYPE_OPTIONS].find(option =>
                  Object.is(option.value, value)
                )}
                onChange={option => onChange(option.value)}
              />
            )}
            name="type"
            control={control}
          />
          <Form.Control.Feedback type="invalid" className="d-block">
            {errors.type?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="agreementType">
          <Form.Label className="text-muted">Agreement Type</Form.Label>
          <Controller
            render={({ field: { onChange, value } }) => (
              <Select
                options={AGREEMENT_TYPE_OPTIONS}
                defaultValue={[...AGREEMENT_TYPE_OPTIONS].find(option =>
                  Object.is(option.value, value)
                )}
                onChange={option => onChange(option.value)}
              />
            )}
            name="agreementType"
            control={control}
          />
          <Form.Control.Feedback type="invalid" className="d-block">
            {errors.agreementType?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="align-items-center">
        <Form.Group as={Col} md="6" controlId="tags">
          <Form.Label className="text-muted">Site Tags</Form.Label>
          <Controller
            render={({ field: { onChange, value } }) => (
              <Select
                isMulti
                options={SITE_TAGS_OPTIONS}
                value={(watchTags || []).map(tag => ({
                  value: tag,
                  label: SITE_TAGS_OPTIONS.find(opt =>
                    Object.is(tag, opt.value)
                  ).label,
                }))}
                onChange={tags => onChange(tags.map(tag => tag.value))}
              />
            )}
            name="tags"
            control={control}
          />
          <Form.Control.Feedback type="invalid" className="d-block">
            {errors.tags?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} controlId="isEnergyCommunityZone">
          <Form.Check
            {...register('isEnergyCommunityZone')}
            type="checkbox"
            label="Energy Community Zone?"
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} md="6" controlId="companyBlacklist">
          <Form.Label className="text-muted">Company Blacklist</Form.Label>
          <Controller
            render={({ field: { onChange, value } }) => (
              <Select
                isMulti
                value={blacklistCompaniesOptions.filter(c =>
                  value.includes(c.value)
                )}
                options={blackListOptions}
                onChange={options => onChange(options.map(opt => opt.value))}
              />
            )}
            name="companyBlacklist"
            control={control}
          />
          <Form.Control.Feedback type="invalid" className="d-block">
            {errors.companyBlacklist?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="companyWhitelist">
          <Form.Label className="text-muted">Company Whitelist</Form.Label>
          <Controller
            render={({ field: { onChange, value } }) => (
              <Select
                isMulti
                value={whitelistCompaniesOptions.filter(c =>
                  value.includes(c.value)
                )}
                options={whiteListOptions}
                onChange={options => onChange(options.map(opt => opt.value))}
              />
            )}
            name="companyWhitelist"
            control={control}
          />
          <Form.Control.Feedback type="invalid" className="d-block">
            {errors.companyWhitelist?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
    </Form>
  )
}

export default SiteEdit
