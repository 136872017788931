import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { Text } from '../../../../Themed'
import { gql, useMutation, useQuery } from '@apollo/client'
import { DANGER, PRIMARY_ALT } from '../../../../../constants/colors'

const LANDOWNERS = gql`
  query LANDOWNERS {
    landowners {
      id
      name
      email
      phone
    }
  }
`

const CREATE_LANDOWNER = gql`
  mutation CREATE_LANDOWNER($landowner: LandownerInput!) {
    createLandownerAdmin(landowner: $landowner) {
      id
      name
      email
      phone
    }
  }
`

const UPDATE_LANDOWNER = gql`
  mutation UPDATE_LANDOWNER($id: ID!, $landowner: LandownerInput!) {
    updateLandownerAdmin(id: $id, landowner: $landowner) {
      id
      name
      email
      phone
    }
  }
`

const DELETE_LANDOWNER = gql`
  mutation DELETE_LANDOWNER($id: ID!) {
    deleteLandownerAdmin(id: $id)
  }
`

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/

const landownerSchema = Yup.object({
  name: Yup.string()
    .trim()
    .required('This is required!')
    .typeError('Enter a valid value!'),
  email: Yup.string()
    .email('Please enter a valid email')
    .required('This is required!'),
  phone: Yup.string().matches(phoneRegExp, {
    message: 'not valid phone no',
    excludeEmptyString: true,
  }),
})

function LandownerForm({ values = {}, onFormSubmit, loading }) {
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...values,
    },
    resolver: yupResolver(landownerSchema),
  })

  // const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
  //   // this part is for stopping parent forms to trigger their submit
  //   if (event) {
  //     // sometimes not true, e.g. React Native
  //     if (typeof event.preventDefault === 'function') {
  //       event.preventDefault()
  //     }
  //     if (typeof event.stopPropagation === 'function') {
  //       // prevent any outer forms from receiving the event too
  //       event.stopPropagation()
  //     }
  //   }

  //   return formHandlers.handleSubmit(async values => {
  //     // your code can go here
  //   })(event)
  // }

  return (
    <Form noValidate onSubmit={handleSubmit(onFormSubmit)}>
      <Form.Group controlId="name">
        <Form.Label className="text-muted">Owner Name*</Form.Label>
        <Form.Control {...register('name')} isInvalid={!!errors.name} />
        <Form.Control.Feedback type="invalid">
          {errors.name?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="email">
        <Form.Label className="text-muted">Owner Email*</Form.Label>
        <Form.Control
          type="email"
          {...register('email')}
          isInvalid={!!errors?.email}
        />
        <Form.Control.Feedback type="invalid">
          {errors.email?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="phone">
        <Form.Label className="text-muted">Owner Phone</Form.Label>
        <Form.Control {...register('phone')} isInvalid={!!errors?.phone} />
        <Form.Control.Feedback type="invalid">
          {errors.phone?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Button
        className="w-100"
        variant="primary"
        type="submit"
        disabled={loading}
      >
        Save Changes
      </Button>
    </Form>
  )
}

function DeleteLandowner({ id }) {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [deleteLandowner, { loading }] = useMutation(DELETE_LANDOWNER, {
    onCompleted: handleClose,
  })

  const handleLandownerDelete = () =>
    deleteLandowner({
      variables: { id },
      update(cache) {
        cache.modify({
          fields: {
            landowners(_, { DELETE }) {
              return DELETE
            },
          },
        })
      },
    })

  return (
    <React.Fragment>
      <Button
        variant="link"
        className="m-0 p-0 text-decoration-none"
        size="sm"
        onClick={handleShow}
      >
        <Text color={DANGER} size={12}>
          Delete
        </Text>
      </Button>
      <Modal show={show} onHide={handleClose} centered backdrop="static">
        <Modal.Body>
          <Text size={24} lines={Infinity} className="text-center mb-4 lh-1">
            Are you sure you want to delete this landowner?
          </Text>
          <div>
            <Button
              onClick={handleLandownerDelete}
              disabled={loading}
              block
              variant="danger"
            >
              Delete
            </Button>
            <Button
              onClick={handleClose}
              disabled={loading}
              block
              variant="secondary"
            >
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

function UpdateLandowner(props) {
  const { landowner } = props
  const { id, ...rest } = landowner

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [updateLandowner, { loading }] = useMutation(UPDATE_LANDOWNER, {
    onCompleted: handleClose,
  })

  const handleLandownerUpdate = data => {
    updateLandowner({ variables: { id, landowner: data } })
  }

  return (
    <React.Fragment>
      <Button
        className="m-0 p-0 text-decoration-none"
        variant="link"
        size="sm"
        onClick={handleShow}
      >
        <Text color={PRIMARY_ALT} size={12}>
          Edit
        </Text>
      </Button>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Text size={24}>Update Landowner</Text>
        </Modal.Header>
        <Modal.Body>
          <LandownerForm
            loading={loading}
            onFormSubmit={handleLandownerUpdate}
            values={rest}
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

function CreateLandowner() {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [createLandowner, { loading }] = useMutation(CREATE_LANDOWNER, {
    onCompleted: handleClose,
  })

  const handleLandOwnerCreate = data =>
    createLandowner({
      variables: { landowner: data },
      update(cache, { data: { createLandownerAdmin } }) {
        cache.modify({
          fields: {
            landowners(existingLandowners = []) {
              const newLandownerRef = cache.writeFragment({
                data: createLandownerAdmin,
                fragment: gql`
                  fragment NewLandowner on Landowner {
                    id
                  }
                `,
              })
              return [newLandownerRef, ...existingLandowners]
            },
          },
        })
      },
    })

  return (
    <>
      <Button variant="outline-primary" size="sm" onClick={handleShow}>
        + Add
      </Button>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Text size={24}>New Landowner</Text>
        </Modal.Header>
        <Modal.Body>
          <LandownerForm
            loading={loading}
            onFormSubmit={handleLandOwnerCreate}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

function LandownersList() {
  const { loading, error, data } = useQuery(LANDOWNERS)

  if (loading) return <p>loading...</p>
  if (error) return <p>error...</p>

  const { landowners } = data

  return (
    <React.Fragment>
      {(landowners || []).map(landowner => (
        <div key={landowner.email} className="pb-1 mb-1 border-bottom">
          <div className="d-flex align-items-baseline">
            <Text size={18} className="mr-2">{`${landowner.name}`}</Text>
            <div className="d-flex align-items-center">
              <UpdateLandowner landowner={landowner} />
              <Text size={12} className="mr-1 ml-1">
                |
              </Text>
              <DeleteLandowner id={landowner.id} />
            </div>
          </div>
          <Text size={12}>{`E: ${landowner.email}`}</Text>
        </div>
      ))}
    </React.Fragment>
  )
}

function Landowners() {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <React.Fragment>
      <Button
        variant="outline-primary"
        className="ml-2"
        size="sm"
        onClick={handleShow}
      >
        Landowners
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <div className="d-flex align-items-center justify-content-center">
            <Text size={24} className="mr-2">
              Landowners
            </Text>
            <CreateLandowner />
          </div>
        </Modal.Header>
        <Modal.Body style={{ minHeight: '80vh' }}>
          <LandownersList />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

export default Landowners
