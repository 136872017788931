import React, { useEffect } from 'react'
import moment from 'moment'
import { Row, Col } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import { Form, Button, Spinner } from 'react-bootstrap'
import { yupResolver } from '@hookform/resolvers/yup'
import { useValidateApiKey } from './hooks'

import { companyUpdateSchema } from './validations'
import 'react-datepicker/dist/react-datepicker.css'

const TAB_OPTIONS = [
  { value: 'ORIGINATE', label: 'Originate' },
  { value: 'DEVELOP', label: 'View Develop' },
  { value: 'ENGINEER', label: 'Feasibility' },
  { value: 'SITING', label: 'Dev. Marketplace' },
  { value: 'FINANCE', label: 'Finance' },
]

const LICENSE_OPTIONS = [
  { value: 'TRIAL', label: 'Trial' },
  { value: 'PAID', label: 'Paid' },
]

const SITING_CLASS_OPTIONS = [
  { value: 'ELITE', label: 'Elite' },
  { value: 'GENERAL', label: 'General' },
]

export function CompanyUpdateForm(props) {
  const { loading, values, onFormSubmit } = props
  const [validate] = useValidateApiKey()
  const defaultValues = {
    ...values,
    sitingClass: values.sitingClass || 'GENERAL',
    licenseStart: values.licenseStart || moment.utc().toDate().toUTCString(),
    licenseEnd:
      values.licenseEnd || moment().add(7, 'days').utc().toDate().toUTCString(),
    dealAnalyticsTrialEnd:
      values.dealAnalyticsTrialEnd ||
      moment().add(10, 'days').utc().toDate().toUTCString(),
    srcLicenseEnd:
      values.srcLicenseEnd ||
      moment().add(90, 'days').utc().toDate().toUTCString(),
    srcLicense: values.srcLicense || 'TRIAL',
  }
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,

    formState: { isValidating, isSubmitting, errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(companyUpdateSchema(validate)),
    defaultValues,
  })
  const onSubmit = data => onFormSubmit(data)

  const watchEnabledTabs = watch('enabledTabs')
  const watchLicense = watch('license')
  const watchedSrcLicenseType = watch('srcLicense')
  const watchedEnabledSRC = watch('srcEnabled')
  const watchedEnabledSolcast = watch('solcastEnabled')
  const isTrial = watchLicense === 'TRIAL'

  useEffect(() => {
    if (values) reset(defaultValues)
  }, [values])

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col className="border-right">
          <Form.Label className="text-muted">
            <b>View License Settings</b>
          </Form.Label>
          <Row>
            <Col>
              <Form.Group controlId="maxProjectCount">
                <Form.Label className="text-muted">Projects Limit</Form.Label>
                <Form.Control
                  type="number"
                  {...register('maxProjectCount')}
                  isInvalid={!!errors.maxProjectCount}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.maxProjectCount?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="maxOptimizationCount">
                <Form.Label className="text-muted">
                  Optimizations Limit
                </Form.Label>
                <Form.Control
                  type="number"
                  {...register('maxOptimizationCount')}
                  isInvalid={!!errors.maxOptimizationCount}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.maxOptimizationCount?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="maxUserCount">
                <Form.Label className="text-muted">Users Limit</Form.Label>
                <Form.Control
                  type="number"
                  {...register('maxUserCount')}
                  isInvalid={!!errors.maxUserCount}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.maxUserCount?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="license">
                <Form.Label className="text-muted">License Type</Form.Label>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <Select
                      options={LICENSE_OPTIONS}
                      // styles={DROPDOWN_STYLES}
                      value={LICENSE_OPTIONS.find(option =>
                        Object.is(option.value, value)
                      )}
                      onChange={option => onChange(option.value)}
                    />
                  )}
                  name="license"
                  control={control}
                  defaultValue={LICENSE_OPTIONS.find(option =>
                    Object.is(option.value, values.license)
                  )}
                />
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.license?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="licenseStart">
                <Form.Label className="text-muted">
                  {isTrial ? 'Trial' : 'License'} Start Date
                </Form.Label>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      dateFormat="MMM/dd/yyyy"
                      selected={new Date(value)}
                      onChange={onChange}
                      customInput={<Form.Control className="w-100" />}
                    />
                  )}
                  name="licenseStart"
                  control={control}
                />
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.licenseStart?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="licenseEnd">
                <Form.Label className="text-muted">
                  {isTrial ? 'Trial' : 'License'} End Date
                </Form.Label>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      dateFormat="MMM/dd/yyyy"
                      selected={new Date(value)}
                      onChange={onChange}
                      customInput={<Form.Control className="w-100" />}
                    />
                  )}
                  name="licenseEnd"
                  control={control}
                />
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.licenseEnd?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="dealAnalyticsTrialEnd">
                <Form.Label className="text-muted d-block">
                  Deal Analytics Trial End Date
                </Form.Label>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      dateFormat="MMM/dd/yyyy"
                      selected={new Date(value)}
                      onChange={onChange}
                      customInput={<Form.Control className="w-100" />}
                    />
                  )}
                  name="dealAnalyticsTrialEnd"
                  control={control}
                />
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.dealAnalyticsTrialEnd?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col className="border-right">
          <Form.Label className="text-muted">
            <b>Tabs & Misc Settings</b>
          </Form.Label>
          <Form.Group controlId="enabledTabs">
            <Form.Label className="text-muted">Enabled Tabs</Form.Label>
            <Controller
              render={({ field: { onChange, value } }) => (
                <Select
                  options={TAB_OPTIONS}
                  isMulti
                  value={watchEnabledTabs.map(enTab => ({
                    value: enTab,
                    label: TAB_OPTIONS.find(opt => Object.is(enTab, opt.value))
                      .label,
                  }))}
                  onChange={tabs => onChange(tabs.map(tab => tab.value))}
                />
              )}
              name="enabledTabs"
              control={control}
            />
          </Form.Group>
          {watchEnabledTabs.includes('SITING') && (
            <Form.Group controlId="sitingClass">
              <Form.Label className="text-muted">Siting Class</Form.Label>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={SITING_CLASS_OPTIONS}
                    value={SITING_CLASS_OPTIONS.find(option =>
                      Object.is(option.value, value)
                    )}
                    onChange={option => onChange(option.value)}
                  />
                )}
                name="sitingClass"
                control={control}
                defaultValue={SITING_CLASS_OPTIONS.find(option =>
                  Object.is(option.value, values.sitingClass)
                )}
              />
              <Form.Control.Feedback type="invalid" className="d-block">
                {errors.sitingClass?.message}
              </Form.Control.Feedback>
            </Form.Group>
          )}
          <Row>
            <Col>
              <Form.Group controlId="pxxEnabled">
                <Form.Check
                  {...register('pxxEnabled')}
                  type="checkbox"
                  label={<Form.Label>Enable PXX</Form.Label>}
                />
              </Form.Group>
              <Form.Group controlId="layersEnabled">
                <Form.Check
                  {...register('layersEnabled')}
                  type="checkbox"
                  label={<Form.Label>Enable Layers</Form.Label>}
                />
              </Form.Group>
              <Form.Group controlId="cashflowEnabled">
                <Form.Check
                  {...register('cashflowEnabled')}
                  type="checkbox"
                  label={<Form.Label>Enable Cashflow</Form.Label>}
                />
              </Form.Group>
              <Form.Group controlId="additionalExclusionsEnabled">
                <Form.Check
                  {...register('additionalExclusionsEnabled')}
                  type="checkbox"
                  label={<Form.Label>Enable Addn. Exclusions</Form.Label>}
                />
              </Form.Group>
              <Form.Group controlId="marketplacePreviewEnabled">
                <Form.Check
                  {...register('marketplacePreviewEnabled')}
                  type="checkbox"
                  label={<Form.Label>Enable VDM Preview</Form.Label>}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="ultra8760Enabled">
                <Form.Check
                  {...register('ultra8760Enabled')}
                  type="checkbox"
                  label={<Form.Label>Enable Ultra Log</Form.Label>}
                />
              </Form.Group>
              <Form.Group controlId="merchantCurveEnabled">
                <Form.Check
                  {...register('merchantCurveEnabled')}
                  type="checkbox"
                  label={<Form.Label>Enable M Curve</Form.Label>}
                />
              </Form.Group>
              <Form.Group controlId="pushToSitesEnabled">
                <Form.Check
                  {...register('pushToSitesEnabled')}
                  type="checkbox"
                  label={<Form.Label>Enable Push to Sites</Form.Label>}
                />
              </Form.Group>
              <Form.Group controlId="sitesInteractiveMapToolsEnabled">
                <Form.Check
                  {...register('sitesInteractiveMapToolsEnabled')}
                  type="checkbox"
                  label={<Form.Label>Enable Interactive Map Tools</Form.Label>}
                />
              </Form.Group>
              <Form.Group controlId="sensitivityCalcEnabled">
                <Form.Check
                  {...register('sensitivityCalcEnabled')}
                  type="checkbox"
                  label={<Form.Label>Enable Sensitivity Calc(HST)</Form.Label>}
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col>
          <Form.Label className="text-muted">
            <b>DNV Settings</b>
          </Form.Label>
          <Row>
            <Col xs={12}>
              <Form.Group controlId="srcEnabled">
                <Form.Check
                  {...register('srcEnabled')}
                  type="checkbox"
                  label={<Form.Label>Enable SRC</Form.Label>}
                />
              </Form.Group>
            </Col>
          </Row>
          {watchedEnabledSRC && (
            <>
              <Row>
                <Col xs={12}>
                  <Form.Group controlId="srcLicense">
                    <Form.Label className="text-muted">
                      SRC License Type
                    </Form.Label>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <Select
                          options={LICENSE_OPTIONS}
                          // styles={DROPDOWN_STYLES}
                          value={LICENSE_OPTIONS.find(option =>
                            Object.is(option.value, value)
                          )}
                          onChange={option => onChange(option.value)}
                        />
                      )}
                      name="srcLicense"
                      control={control}
                      defaultValue={LICENSE_OPTIONS.find(option =>
                        Object.is(option.value, values.srcLicense)
                      )}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {errors.srcLicense?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              {Object.is(watchedSrcLicenseType, 'PAID') ? (
                <Form.Group controlId="srcApiKey">
                  <Form.Label className="text-muted">SRC API Key</Form.Label>
                  <Form.Control
                    {...register('srcApiKey')}
                    isInvalid={!!errors.srcApiKey}
                    as="textarea"
                    rows={3}
                    className="w-100"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.srcApiKey?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              ) : (
                <Form.Group controlId="srcLicenseEnd">
                  <Form.Label className="text-muted d-block">
                    SRC License End Date
                  </Form.Label>
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        dateFormat="MMM/dd/yyyy"
                        selected={new Date(value)}
                        onChange={onChange}
                        customInput={<Form.Control className="w-100" />}
                      />
                    )}
                    name="srcLicenseEnd"
                    control={control}
                  />
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.srcLicenseEnd?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              )}
            </>
          )}
          <Row>
            <Col xs={12}>
              <Form.Group controlId="solcastEnabled">
                <Form.Check
                  {...register('solcastEnabled')}
                  type="checkbox"
                  label={<Form.Label>Enable Solcast</Form.Label>}
                />
              </Form.Group>
            </Col>
          </Row>
          {watchedEnabledSolcast && (
            <>
              <Row>
                <Col xs={12}>
                  <Form.Group controlId="solcastApiKey">
                    <Form.Label className="text-muted">
                      SOLCAST API Key
                    </Form.Label>
                    <Form.Control
                      {...register('solcastApiKey')}
                      isInvalid={!!errors.solcastApiKey}
                      as="textarea"
                      rows={3}
                      className="w-100"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.solcastApiKey?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group controlId="solcastDownloadEnabled">
                    <Form.Check
                      {...register('solcastDownloadEnabled')}
                      type="checkbox"
                      label={<Form.Label>Enable Solcast Download</Form.Label>}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      <Button
        disabled={loading || isValidating || isSubmitting}
        variant="primary"
        type="submit"
      >
        <div className="d-flex align-items-center justify-content-center">
          {(loading || isValidating || isSubmitting) && (
            <Spinner
              className="mr-2"
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          <span>{isValidating ? 'Validating' : 'Save Settings'}</span>
        </div>
      </Button>
    </Form>
  )
}
