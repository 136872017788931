import { useForm, Controller } from 'react-hook-form'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { Form } from 'react-bootstrap'
import { yupResolver } from '@hookform/resolvers/yup'
import { siteAgreementSchema } from './validations'

function EditForm(props) {
  const { formRef, values, onFormSubmit } = props

  const {
    handleSubmit,
    control,

    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(siteAgreementSchema),
    defaultValues: values,
  })
  const onSubmit = data => onFormSubmit(data)

  return (
    <Form ref={formRef} noValidate onSubmit={handleSubmit(onSubmit)}>
      <Form.Group controlId="siteMetadata.exclusivityExpiry">
        <Form.Label className="text-muted d-block">Edit Expiry Date</Form.Label>
        <Controller
          render={({ field: { onChange, value } }) => (
            <DatePicker
              dateFormat="MMM/dd/yyyy"
              selected={new Date(value)}
              onChange={onChange}
              minDate={moment().add(1, 'days').toDate()}
              customInput={<Form.Control className="w-100" />}
            />
          )}
          name="siteMetadata.exclusivityExpiry"
          control={control}
        />
        <Form.Control.Feedback type="invalid" className="d-block">
          {errors.siteMetadata?.exclusivityExpiry?.message}
        </Form.Control.Feedback>
      </Form.Group>
    </Form>
  )
}

export default EditForm
