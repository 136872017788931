import { useState, useEffect, useRef } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'
import { SiteEdit } from './forms'

const { REACT_APP_USER_CLIENT, REACT_APP_DEFAULT_BLACKLIST } = process.env

const TECHNOLOGY_OPTIONS = [
  { label: 'Wind', value: 'WIND' },
  { label: 'Bess', value: 'BESS' },
  { label: 'Hydrogen', value: 'HYDROGEN' },
]

function UpdateSite(props) {
  const { site, className = '', onUpdate, loading, isSuccess } = props
  const [show, setShow] = useState(false)
  const formRef = useRef(null)

  const siteTechOpts = site.project
    ? [{ label: 'Solar', value: 'SOLAR', isFixed: true }, ...TECHNOLOGY_OPTIONS]
    : TECHNOLOGY_OPTIONS

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  useEffect(() => {
    if (isSuccess) handleClose()
  }, [isSuccess])

  const handleSubmit = (
    ref => () =>
      formRef.current.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true })
      )
  )(formRef)

  const handleSiteUpdate = siteData => {
    const { updatedAt, createdAt, agreements, ...restSiteData } = siteData
    onUpdate({ variables: { id: site.id, site: restSiteData } })
  }

  const isFresh =
    new Date(site.createdAt).getTime() === new Date(site.updatedAt).getTime()

  return (
    <>
      <Button
        onClick={handleShow}
        variant="outline-primary"
        size="sm"
        className={className}
      >
        Edit
      </Button>
      <Modal size="lg" show={show} onHide={handleClose} backdrop="static">
        <Modal.Header>
          <Modal.Title className="w-100">
            <div className="d-flex justify-content-between">
              <div>
                <span>Site Information</span>&nbsp;
              </div>
              {site.project && (
                <span>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`${REACT_APP_USER_CLIENT}/develop/edit/results/${site.project}`}
                    style={{ fontSize: 14 }}
                  >
                    View Project
                  </a>
                </span>
              )}
            </div>{' '}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SiteEdit
            formRef={formRef}
            onFormSubmit={handleSiteUpdate}
            values={{
              ...site,
              ...(isFresh
                ? {
                    companyBlacklist: (REACT_APP_DEFAULT_BLACKLIST || '').split(
                      ' '
                    ),
                  }
                : {}),
              center: {
                lat: site?.kml?.center?.[1],
                lng: site?.kml?.center?.[0],
              },
            }}
            siteTechOpts={siteTechOpts}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={loading}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={loading}
            className="d-flex align-items-center justify-content-center"
          >
            {loading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mr-1"
              />
            )}
            <span>Save</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default UpdateSite
