import * as Yup from 'yup'

// const TYPE_ERR_MSG = 'Enter a valid value!'
// const REQUIRED_ERR_MSG = 'This is required!'

export const siteDetailsSchema = (site, hasKml, hasThumbnail) =>
  Yup.object({
    name: Yup.string()
      .trim()
      .required('This is required!')
      .typeError('Enter a valid value!'),
    iso: Yup.string()
      .required('This is required!')
      .typeError('Please select an ISO'),
    leaseRate: Yup.number()
      .required('This is required!')
      .min(0, 'This should be greater than 0!')
      .typeError('Enter a valid value!'),
    leaseEsc: Yup.number()
      .min(0)
      .required('This is required!')
      .min(0, 'This should be greater than 0!')
      .typeError('Enter a valid value!'),
    offtakerDemandInMWh: Yup.number()
      .min(0)
      .required('This is required!')
      .typeError('Enter a valid value!'),
    nOfftakers: Yup.number()
      .min(0)
      .required('This is required!')
      .positive('Enter a value greater than 0!')
      .typeError('Enter a valid value!'),
    isEnergyCommunityZone: Yup.boolean(),
    bessCapacity: Yup.number().min(0).typeError('Enter a valid value!'),
    bessEnergy: Yup.number().min(0).typeError('Enter a valid value!'),
    transmissionLine: Yup.string().required('Enter a valid value!'),
    purchasePrice: Yup.number().min(0).typeError('Enter a valid value!'),
    type: Yup.string().required('This is required!'),
    firstLookExpiry: Yup.date(),
    agreementType: Yup.string()
      .required('This is required!')
      .typeError('Enter a valid value!'),
    tags: Yup.array(),
    companyBlacklist: Yup.array(),
    companyWhitelist: Yup.array(),
    landowners: Yup.object({
      all: Yup.array(),
      anchor: Yup.array(),
    }),
    kmlFile: Yup.mixed().when('project', {
      is: () => !!site.project || hasKml,
      then: () => Yup.mixed(),
      otherwise: () =>
        Yup.mixed()
          .required('Please select kml file')
          .typeError('Please select kml file')
          .test(
            'fileSize',
            'The file is too large.',
            value => value && value.size <= 25 * 1000 * 1000
          ),
    }),
    thumbnailFile: Yup.mixed().when('project', {
      is: () => !!site.project || hasThumbnail,
      then: () => Yup.mixed(),
      otherwise: () =>
        Yup.mixed()
          .required('Please select thumbnail')
          .typeError('Please select thumbnail')
          .test(
            'fileSize',
            'The file is too large.',
            value => value && value.size <= 25 * 1000 * 1000
          ),
    }),
    center: Yup.object().when('project', {
      is: () => !!site.project,
      then: () => Yup.object(),
      otherwise: () =>
        Yup.object({
          lat: Yup.number()
            .min(-90, 'Must be greater than or equal to -90')
            .max(90, 'Must be less than or equal to 90')
            .required('This is required!')
            .typeError('Enter a valid value!'),
          lng: Yup.number()
            .min(-180, 'Must be greater than or equal to -180')
            .max(180, 'Must be less than or equal to 180')
            .required('This is required!')
            .typeError('Enter a valid value!'),
        }),
    }),
    technologies: Yup.array().of(Yup.string().required()),
    boundaryAreaInAcres: Yup.number().when('project', {
      is: () => !!site.project,
      then: () => Yup.number().nullable(),
      otherwise: () =>
        Yup.number().positive().required().typeError('Enter a valid number'),
    }),
    buildableAreaInAcres: Yup.number().when('project', {
      is: () => !!site.project,
      then: () => Yup.number().nullable(),
      otherwise: () =>
        Yup.number().positive().required().typeError('Enter a valid number'),
    }),
  })
