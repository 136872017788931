import React from 'react'
import Select from 'react-select'
import { Button, Form, Spinner } from 'react-bootstrap'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { fwdCurveSchema } from './validations'

const FWD_CURVE_FREQ_OPTIONS = [
  { label: 'Monthly', value: 'monthly' },
  { label: 'Hourly', value: 'hourly', isDisabled: true },
]

export function ForwardCurveForm(props) {
  const { loading, onFormSubmit } = props

  const defaultValues = { frequency: 'monthly' }

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(fwdCurveSchema),
    defaultValues,
  })
  const onSubmit = data => onFormSubmit(data)

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Form.Group controlId="frequency">
        <Form.Label>Frequency</Form.Label>
        <Controller
          render={({ field: { onChange, value } }) => (
            <Select
              options={FWD_CURVE_FREQ_OPTIONS}
              // styles={DROPDOWN_STYLES}
              defaultValue={value}
              onChange={option => onChange(option.value)}
            />
          )}
          name="frequency"
          control={control}
        />
        <Form.Control.Feedback type="invalid" className="d-block">
          {errors.frequency?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="clearingCode">
        <Form.Label>Clearing Code</Form.Label>
        <Form.Control
          {...register('clearingCode')}
          isInvalid={!!errors.clearingCode}
          placeholder="Clearing Code"
        />
        <Form.Control.Feedback type="invalid">
          {errors.clearingCode?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Button disabled={loading} variant="primary" type="submit">
        <div className="d-flex align-items-center justify-content-center">
          {loading && (
            <Spinner
              className="mr-2"
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          <span>Get Forward Curve</span>
        </div>
      </Button>
    </Form>
  )
}
