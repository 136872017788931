export const WHITE = '#fff'
export const PRIMARY = '#33CCFF'
export const PRIMARY_ALT = '#2072bd'
export const GREY = '#707070'
export const GREY_ALT = '#eaeaea'
export const DANGER = '#dc3545'
export const SUCCESS = '#28A745'

//New color scheme.
export const PRIMARY_1 = '#25274D'
export const PRIMARY_2 = '#67C8FA'

export const SECONDARY_1 = '#4D506D'
export const SECONDARY_2 = '#71A1C0'
export const SECONDARY_3 = '#9D2872'
export const SECONDARY_4 = '#00D8DD'
export const SECONDARY_5 = '#9076FF'

export const NEUTRAL_1 = '#FFFFFF'
export const NEUTRAL_2 = '#E9ECEE'
export const NEUTRAL_3 = '#CBD0D4'
export const NEUTRAL_4 = '#A2A7AC'
