import React, { useContext, useEffect } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { ConversationsContext, Stack } from '..'
import { Form } from 'react-bootstrap'
import Select from 'react-select'

const conversationSelectorSchema = Yup.object({
  companies: Yup.array().of(Yup.string()),
  sites: Yup.array().of(Yup.string()),
  sort: Yup.object().shape({
    createdAt: Yup.number(),
    updatedAt: Yup.number(),
    site: Yup.number(),
    company: Yup.number(),
  }),
})

const SORT_OPTIONS = [
  {
    value: { createdAt: -1 },
    label: 'Most Recent',
  },
  {
    value: { updatedAt: -1 },
    label: 'Last Update',
  },
  {
    value: { site: 1 },
    label: 'Site',
  },
  {
    value: { company: 1 },
    label: 'Company',
  },
]

function ConversationFilterForm({ onSubmit }) {
  const { sites, companies } = useContext(ConversationsContext)

  const {
    handleSubmit,
    control,
    watch,

    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(conversationSelectorSchema),
    defaultValues: { companies: [], sites: [], sort: { updatedAt: -1 } },
  })

  useEffect(() => {
    // TypeScript users
    // const subscription = watch(() => handleSubmit(onSubmit)())
    const subscription = watch(handleSubmit(onSubmit))
    return () => subscription.unsubscribe()
  }, [handleSubmit, watch])

  const siteOptions = sites.map(({ id, name }) => ({ value: id, label: name }))
  const companyOptions = companies.map(({ id, name }) => ({
    value: id,
    label: name,
  }))

  return (
    <Form noValidate>
      <Stack direction="horizontal" className="w-100" gap={2}>
        <Form.Group className="m-0 w-50" controlId="companies">
          <Controller
            render={({ field: { onChange } }) => (
              <Select
                options={companyOptions}
                isMulti
                placeholder="Select Companies..."
                onChange={companies => {
                  onChange(companies.map(({ value }) => value))
                }}
              />
            )}
            name="companies"
            control={control}
          />
          <Form.Control.Feedback type="invalid" className="d-block">
            {errors.companies?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="m-0 w-50" controlId="sites">
          <Controller
            render={({ field: { onChange } }) => (
              <Select
                options={siteOptions}
                isMulti
                placeholder="Select Sites..."
                onChange={companies =>
                  onChange(companies.map(({ value }) => value))
                }
              />
            )}
            name="sites"
            control={control}
          />
          <Form.Control.Feedback type="invalid" className="d-block">
            {errors.sites?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="m-0 w-50" controlId="sort">
          <Controller
            render={({ field: { onChange } }) => (
              <Select
                options={SORT_OPTIONS}
                placeholder="Sort..."
                onChange={({ value }) => {
                  onChange(value)
                }}
              />
            )}
            name="sort"
            control={control}
          />
          <Form.Control.Feedback type="invalid" className="d-block">
            {errors.companies?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Stack>
    </Form>
  )
}

function ConversationFilter({ onChange }) {
  return <ConversationFilterForm onSubmit={onChange} />
}

export default ConversationFilter
