export const SIGN_UP = '/signup'
export const SIGN_IN = '/signin'
export const ANALYTICS_SOURCE = '/data'
export const CMS = '/cms'
export const USAGE = '/usage'
export const COMPANIES = '/companies'
export const MANAGERS = '/managers'
export const EQUIPMENTS = '/equipments'
export const PROJECTS = '/projects'
export const LANDING = '/'
export const ORIGINATIONS = '/originations'
export const MARKET_DATA_SERVICES = '/mds'
export const EMAIL_DOMAINS = '/domains'
export const PREQUALIFIED_SITES = '/prequalified_sites'
export const SITE_UPLOAD = '/site_upload'
export const CONVERSATIONS = '/conversations'
