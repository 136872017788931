import React from 'react'
import { useForm } from 'react-hook-form'
import { Form, Button, Spinner } from 'react-bootstrap'
import { yupResolver } from '@hookform/resolvers/yup'

import { signInSchema } from './validations'

export function SignInForm(props) {
  const { onSignIn, loading } = props
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(signInSchema),
  })
  const onSubmit = data => onSignIn(data)

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Form.Group controlId="email">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          size="lg"
          {...register('email')}
          isInvalid={!!errors.email}
        />
        <Form.Control.Feedback type="invalid">
          {errors.email?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          size="lg"
          {...register('password')}
          isInvalid={!!errors.password}
        />
        <Form.Control.Feedback type="invalid">
          {errors.password?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Button size="lg" disabled={loading} variant="primary" type="submit">
        <div className="d-flex align-items-center justify-content-center">
          {loading && (
            <Spinner
              className="mr-2"
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          <span>Sign In</span>
        </div>
      </Button>
    </Form>
  )
}
