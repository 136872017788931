import { gql } from '@apollo/client'

export const GET_PREQUALIFIED_SITES = gql`
  query GET_PREQUALIFIED_SITES {
    preQualifiedSites: sites {
      id
      name
      project
      isPublished
      submissionId
      iso
      leaseRate
      leaseEsc
      purchasePrice
      transmissionLine
      bessCapacity
      bessEnergy
      offtakerDemandInMWh
      nOfftakers
      isEnergyCommunityZone
      type
      agreementType
      firstLookExpiry
      tags
      boundaryAreaInAcres
      buildableAreaInAcres
      createdAt
      updatedAt
      landowners {
        all {
          id
          name
          email
        }
        anchor {
          id
          name
          email
        }
      }
      kml {
        url
        center
      }
      thumbnail
      agreements {
        id
        status
        type
        company {
          id
          name
        }
        user {
          firstName
          lastName
          email
        }
        agreementURL
        updatedAt
      }
      address {
        streetAddress
        city
        state
        postalCode
        country
      }
      parcels {
        id
        owner {
          name
          email
          phone
        }
        acreage
        number
        centroid
        iso
        leaseRate
        leaseEsc
        offtakerDemandInMWh
        nOfftakers
        isEnergyCommunityZone
        boundaries {
          path
        }
      }
      companyBlacklist
      companyWhitelist
      technologies
    }
    companies {
      id
      name
    }
    landowners {
      id
      name
      email
      phone
    }
  }
`
